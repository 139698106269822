import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import 'animate.css';
import { Menu as MenuContainer, ContainerMenu } from './styled';

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenuOpen = () => {
        setIsOpen(true);
        if (window.innerWidth <= 768) document.querySelector('.x-icon')?.classList.add('animate__rotateIn');
        if (window.innerWidth <= 768) document.querySelector('.tab-buttons')?.classList.add('animate__bounceInRight');
    }

    const toggleMenuClose = () => {
        setIsOpen(false);
        if (window.innerWidth <= 768) document.querySelector('.x-icon')?.classList.remove('animate__rotateIn');
    }



    return (
        <ContainerMenu showMenu={isOpen}>

            <FaBars 
            size={'30'} 
            color={'gray'} 
            className="hamburger-icon" 
            display={isOpen ? 'none' : 'block'}
            onClick={toggleMenuOpen}/>

            <FaTimes 
            size={'30'} 
            color="gray"
            className="x-icon animate__animated" 
            display={isOpen ? 'block' : 'none'} 
            onClick={toggleMenuClose}/>

            <MenuContainer className='tab-buttons animate__animated'>
                <Link className="servicos-tab" to={"/servicos"}>
                    Serviços
                </Link>

                <Link className="faleconosco-tab" to={"/fale-conosco"}>
                    Fale Conosco
                </Link>
            </MenuContainer>

        </ContainerMenu>
    );
}

export default Menu;