import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../utils/GlobalContext';
import './style.css';

const HomeContactUs = () => {
    const { setGlobalValue } = useContext(GlobalContext);

    const handleButtonClick = () => setGlobalValue('Agende uma consulta (Page: Home)');

    return (
        <section className='faleconosco-section'>
            <div className='faleconosco-content'>

            <div className='faleconosco-content-img-div'>
                <div className="faleconosco-content-img">
                    
                </div>
            </div>

            <span className='space'></span>
            
                <div className='faleconosco-content-text-descricao-div'>
                    <h2 className='faleconosco-content-text-title'>Está precisando de ajuda?</h2>

                    <p className='faleconosco-content-text-descricao'>
                        Somos uma equipe de especialistas em tecnologia que estão prontos para ajudá-lo a
                        desenvolver soluções personalizadas que atendam às suas necessidades específicas.
                    </p>

                    <p className='faleconosco-content-text-descricao'>
                        Se precisa melhorar a eficiência do seu negócio ou enfrentar desafios complexos,
                        estamos aqui para ajudar. Não perca tempo procurando a solução perfeita.
                    </p>

                    <p className='faleconosco-content-text-descricao'>
                        Entre em contato conosco hoje e deixe-nos ajudá-lo a expandir seus negócios com
                        soluções de software personalizadas!
                    </p>

                    <Link to={"/fale-conosco"} onClick={handleButtonClick} className="faleconosco-content-botao-agendamento">
                        Agende uma consulta
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default HomeContactUs;