import React, { useState, useCallback, useRef, useContext, useEffect } from 'react';
import emailjs from 'emailjs-com';
import GlobalContext from '../../../../utils/GlobalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';

const ContactUsForm = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [localization, setLocalization] = useState("")
    const [botaoClicado, setBotaoClicado] = useState("")

    const { globalValue } = useContext(GlobalContext);
    useEffect(() => { setBotaoClicado(globalValue); }, [globalValue]);

    function temErroNoFormulario() {
        if (name === "") {
            toast.error("Informe seu nome.");
            return true;
        }
        if (email === "") {
            toast.error("Informe seu email.");
            return true;
        }
        if (message === "") {
            toast.error("Informe uma messagem.");
            return true;
        }
        if (phone.replace(/\D/g, "").length < 11) {
            toast.error("Informe seu contato corretamente.");
            return true;
        }

        return false;
    }

    const sendEmail = (event: any) => {
        event.preventDefault();
        console.log(phone.replace(/\D/g, "").length)

        if (temErroNoFormulario()) return;

        const templateParams = {
            name: name,
            email: email,
            phone: phone,
            message: message,
            localization: localization,
            botaoClicado: botaoClicado
        }

        emailjs.send("service_wstxnbu", "template_ikk676j", templateParams, "udi-8R77JUI8OA3GR")
             .then((response) => {
                 console.log(response.text);
 
                 setName('');
                 setEmail('');
                 setLocalization('');
                 setMessage('');
                 setPhone('');
 
                 toast.success("Enviando e-mail")
             },
                 (error) => { console.log(error.text); });
     }

            function handlePhoneNumberChange(event: any) {
                const phoneNumberString = event.target.value.replace(/\D/g, "");
                const phoneNumberFormatted = phoneNumberString.replace(
                    /^(\d{2})(\d{5})(\d{4})$/, // Expressão regular para formatar o número no padrão (00) 00000-0000
                    "($1) $2-$3"
                );
                setPhone(phoneNumberFormatted);
            }

        return (
            <section className='faleconosco-form-section'>
                <ToastContainer />
                <div className='faleconosco-form-content'>
                    <div className='faleconosco-form-content-text'>
                        <h2 className='faleconosco-form-text-title'> Em que podemos ajudar você? </h2>

                        <p className='faleconosco-form-text-descricao'>
                            Ao preencher nosso formulário de contato, você pode ter certeza de que estamos prontos
                            para ouvir suas necessidades e fornecer soluções personalizadas. Nós nos dedicamos a fornecer
                            um atendimento de qualidade e um serviço excepcional a todos os nossos clientes, e isso começa
                            com o formulário de contato.
                        </p>
                    </div>

                    <span className='space'></span>

                    <form className='faleconosco-form-content-formulario' onSubmit={sendEmail}>
                        <div className='faleconosco-form-formulario-container faleconosco-form-formulario-div'>
                            <label className='faleconosco-form-formulario-label'>
                                Qual Seu nome?

                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className='faleconosco-form-formulario-input' />
                            </label>

                            <label className='faleconosco-form-formulario-label lbl-email'>
                                Qual o seu e-mail?

                                <input
                                    type="email"
                                    value={email}
                                    placeholder='seu-email@gmail.com'
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='faleconosco-form-formulario-input' />
                            </label>
                        </div>

                        <div className='faleconosco-form-formulario-div'>
                            <label className='faleconosco-form-formulario-label'>
                                Qual o seu telefone?

                                <input
                                    type="text"
                                    value={phone}
                                    placeholder='(00) 00000-0000'
                                    onChange={handlePhoneNumberChange}
                                    maxLength={15}
                                    className='faleconosco-form-formulario-input' />
                            </label>
                        </div>

                        <div className='faleconosco-form-formulario-div'>
                            <label className='faleconosco-form-formulario-label'>
                                Qual a sua localização?

                                <input
                                    type="text"
                                    value={localization}
                                    onChange={(e) => setLocalization(e.target.value)}
                                    className='faleconosco-form-formulario-input' />
                            </label>
                        </div>

                        <div className='faleconosco-form-formulario-div'>
                            <label className='faleconosco-form-formulario-label'>
                                Como podemos te ajudar?

                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className='faleconosco-form-formulario-textarea'>
                                </textarea>
                            </label>
                        </div>

                        <div className='faleconosco-form-formulario-botao-div'>
                            <button className='faleconosco-form-formulario-botao'>Enviar</button>
                        </div>
                    </form>
                </div>
            </section>);
    }

    export default ContactUsForm;