import React, { useState, useEffect } from 'react';
import './styled';
import { LoadingContainer, LoadingContentImage } from './styled';

const Loading = () => {
    const [isActive, setIsActive] = useState<boolean>(true);

    function loadGIF() {
        let contador = 0;

        if(contador === 0) {
            setIsActive(false);
            contador++;
        }
    };

    function executarLoadGIFAoAbrirAPagina() {
        setTimeout(() =>  loadGIF(), 3500);
    }

    function naoExecutarLoadingAoClicarNosBotoes() {
        document.addEventListener('click', event => {
            let ehUmBotaoOuLink = event.target as HTMLElement;

            if(ehUmBotaoOuLink.nodeType == Node.ELEMENT_NODE) setIsActive(false);
        })
    };

    executarLoadGIFAoAbrirAPagina()
    naoExecutarLoadingAoClicarNosBotoes();

    return (
        <LoadingContainer isActive={isActive}>
            <LoadingContentImage>
                <div className='loading-logo-bygnexus'></div>
            </LoadingContentImage>
        </LoadingContainer>
    );
};

export default Loading;
