import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/header';
import './style.css';
import ServicesBanner from './components/ServicesBanner/index';
import ServicesApplicationDevelopment from './components/ServicesApplicationDevelopment/index';
import ServicesSystemDevelopment from './components/ServicesSystemsDevelopment/index';
import ServicesTechnicalConsultancy from './components/ServicesTechnicalConsultancy/index';
import Loading from '../../components/Loading';

const ServicePage = () => {
    return (
        <>
            <Loading/>
            <Header />
            <main>
                <ServicesBanner/>
                <ServicesApplicationDevelopment/>
                <ServicesSystemDevelopment/>
                <ServicesTechnicalConsultancy/>
            </main>
            <Footer />
        </>
    );
}

export default ServicePage;