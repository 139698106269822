import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../utils/GlobalContext';
import './style.css';

const ServicesSystemDevelopment = () => {
    const { setGlobalValue } = useContext(GlobalContext);

    const handleButtonClick = () => setGlobalValue('Desenvolvimento de sistemas web (Page: Serviços)');

    return (
        <section className="desenvolvimento-de-sistemas-section">
            <div className='desenvolvimento-de-sistemas-content'>
                <div className='desenvolvimento-de-sistemas-text-div'>
                    <h2 className='desenvolvimento-de-sistemas-text-title'> Desenvolvimento de sistemas web </h2>

                    <div className='desenvolvimento-de-sistemas-text-descricao'>
                        <p className='desenvolvimento-de-sistemas-content-text-descricao'>
                            Criamos sites personalizados para o seu negócio. Priorizamos a responsividade, velocidade
                            e facilidade de navegação.
                        </p>

                        <p className='desenvolvimento-de-sistemas-content-text-descricao'>
                            Nós trabalhamos com você em cada etapa para fornecer soluções personalizadas que
                            atendam suas expectativas e excedam suas metas de negócios.
                        </p>

                        <p className='desenvolvimento-de-sistemas-content-text-descricao'>
                            Conte conosco para alcançar seus objetivos com um site personalizado para sua empresa.
                        </p>
                    </div>

                    <Link to={"/fale-conosco"} onClick={handleButtonClick} className='desenvolvimento-de-sistemas-content-botao-obter-site-personalizado'>Obtenha seu sistema personalizado agora</Link>
                </div>

                <span className='servico-space'></span>

                <div className='desenvolvimento-de-sistemas-content-img-div'>
                    <div className='desenvolvimento-de-sistemas-content-img'></div>
                </div>
            </div>
        </section>
    );
}

export default ServicesSystemDevelopment;