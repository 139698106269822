import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/header';
import HomeBanner from './components/HomeBanner/banner';
import HomeContactUs from './components/HomeContactUs';
import HomeTestimony from './components/HomeTestimony';
import HomePresentation from './components/HomePresentation';
import './style.css';
import WhyWeChange from './components/WhyWeChange';
import Loading from '../../components/Loading';

export const HomePage = () => {
    return (
        <>
            <Loading/>
            <main>
                <Header />
                <HomeBanner />
                <WhyWeChange/>
                <HomeTestimony />
                <HomePresentation />
                <HomeContactUs />
                <Footer />
            </main>
        </>
    );
}

export default HomePage;