import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const ContactUsClients = () => {
    return (
        <section className='faleconosco-clients-section'>
            <div className='faleconosco-clients-content'>

                <div className='faleconosco-clients-content-img'></div>

                <span className='space'></span>

                <div className='faleconosco-clients-content-text'>
                    <h2 className='faleconosco-clients-text-title'> Faça como nossos clientes e busque seu Nexus </h2>

                    <p className='faleconosco-clients-text-descricao'>
                        Nossa equipe especializada está pronta para ajudá-lo a superar desafios em tecnologia e programação.
                        Entre em contato e veja como podemos transformar suas ideias em soluções inovadoras e eficientes.
                    </p>
                    <p className='faleconosco-clients-text-descricao'>
                        Assim como os clientes abaixo, você também encontrará sucesso. Junte-se a nós e alcance seus objetivos!
                    </p>

                    <div className='faleconosco-clients-text-clients'>
                        <Link to={"https://www.instagram.com/arvetex.pa/"} className='faleconosco-clients-arvetex'></Link>
                        <Link to={"https://www.instagram.com/alquimiadocheff/"} className='faleconosco-clients-alquimia'></Link>
                        <Link to={"https://www.instagram.com/unifloresta/"} className='faleconosco-clients-uniconsult'></Link>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ContactUsClients;