import styled from 'styled-components';

interface StyledComponentProps {
    showMenu: boolean;
}

export const ContainerMenu = styled.div<StyledComponentProps>`
position: absolute;
right: 20px;

.tab-buttons { 
    display: ${(props) => (props.showMenu ? 'block' : 'none')}; 
}

@media (min-width: 1024px) {
    .tab-buttons { 
        display: flex; 
        padding: 0; 
        margin-top: 20px;
        position: relative;
    }

    .hamburger-icon { display: none; }
    .x-icon { display: none; }
}
`;

export const Menu = styled.div`
margin: 1.8rem 0;
height: 68px;
display: flex;
align-items: center;
flex-shrink: 0;
z-index: 4;

position: absolute;
top:0;
right: 15px;

.servicos-tab {
    margin: 0 1.55rem 0.2rem 0;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.4;
    color: #f8f8ff;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    flex-shrink: 0;
    text-decoration: none;
}

.faleconosco-tab {
    width: 12.6rem;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.4;
    color: #11111F;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: solid 0.2rem var(--bg-gray-buttons);
    box-sizing: border-box;
    background-color: var(--bg-gray-buttons);
    border-radius: 0.8rem;
    flex-shrink: 0;
    text-decoration: none;
    padding: 6px 21px;
}

@media (max-width: 768px) {
    margin: 0;
    flex-direction: column;
    top: 65px;
    right: -19px;

    width: 100vw;

    background: rgba(0, 0, 0, 0.91);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.1px);
    -webkit-backdrop-filter: blur(1.1px);

    .servicos-tab {
        display: flex;
        margin: 0;
        width: 100vw;

        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 6px 21px;
        font-weight: normal;
        border-bottom: 0.025px solid rgba(128, 128, 128, 0.2);
        border-top: 0.025px solid rgba(128, 128, 128, 0.2);
    }

    .faleconosco-tab {
        width: 100vw;
        border: none;
        background: none;
        color: #fff;
    }
}

@media (min-width: 1024px) {
    .faleconosco-tab {
        padding: 5px 21px;
    }
}
`;