import React from 'react';
import ContactUsForm from './components/ContactUsForm';
import ContactUsInfo from './components/ContactUsInfo';
import Footer from '../../components/Footer';
import Header from '../../components/Header/header';
import './style.css';
import ContactUsClients from './components/ContactUsClients';
import Loading from '../../components/Loading';

const ContactUsPage = () => {
    return (
        <>
        <Loading/>
        <main>
            <Header />
            <ContactUsForm />
            <ContactUsInfo />
            <ContactUsClients/>
            <Footer />
        </main>
        </>
    );
};

export default ContactUsPage;