import {SVGProps} from 'react';
import styled from 'styled-components';


export const WhyWeChangeContainer = styled.div`
  background: #11111F;
  color: #F8F8FF;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid gray;
  font-family: 'Paytone One', sans-serif;

  @media (max-width: 768px) {
    height: 360px;
  }

  @media (min-width: 1024px) {
    padding: 30px 76px 30px 76px;
  }
`;

export const WhyWeChangeContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    .whywechange-content-image {
      display: none;
    }
  }
`;

export const WhyWeChangeText = styled.div`

  .whywechange-content-title {
    font-size: 21px;
    line-height: 24px;
    text-align: !normal;
    font-weight: none;
    margin-bottom: 25px;
    margin-top: 26px;
  }

  .whywechange-content-subtitle {
    font-size: 14px;
    line-height: 16.5px;
  }

  @media (min-width: 1024px) {
    .whywechange-content-title {
      width: 90%;
    }

    .whywechange-content-subtitle {
      width: 75%;
      font-size: 30px;
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }

  @media (min-width: 1280px) {
    .whywechange-content-title {
      font-size: 32px;
    }
    
    .whywechange-content-subtitle {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }
`;


interface SvgImageProps extends SVGProps<SVGImageElement> {
    href: string;
}

export const Image = styled.image<SvgImageProps>`
  width: 100%;
  height: 300px;

`;