import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../utils/GlobalContext';
import './style.css';

const ServicesApplicationDevelopment = () => {
    const { setGlobalValue } = useContext(GlobalContext);

    const handleButtonClick = () => setGlobalValue('Desenvolvimento de aplicativos (Page: Serviços)');

    return (
        <section className='desenvolvimento-de-aplicativos-content-section'>
            <div className='desenvolvimento-de-aplicativos-content'>

                <div className='desenvolvimento-de-aplicativos-content-img-div'>
                    <div className='desenvolvimento-de-aplicativos-content-img'></div>
                </div>

                <span className='space'></span>

                <div className='desenvolvimento-de-aplicativos-content-text'>
                    <h2 className='desenvolvimento-de-aplicativos-content-text-title'>Desenvolvimento de aplicativos</h2>

                    <div className='desenvolvimento-de-aplicativos-content-text-descricao-div'>
                        <p className='desenvolvimento-de-aplicativos-content-text-descricao'>
                            Oferecemos serviços de desenvolvimento de software personalizado, design de aplicativos e
                            desenvolvimento de sites. Nosso objetivo é fornecer soluções econômicas que atendam
                            às suas necessidades de negócio.
                        </p>

                        <p className='desenvolvimento-de-aplicativos-content-text-descricao'>
                            Nossa equipe experiente de desenvolvedores trabalha em parceria com você para entender suas
                            necessidades e fornecer soluções personalizadas que atendam às suas expectativas
                            e superem suas metas de negócios.
                        </p>

                        <p className='desenvolvimento-de-aplicativos-content-text-descricao'>
                            Deixe-nos ajudá-lo a criar o aplicativo perfeito para sua empresa.
                        </p>

                        <Link to={"/fale-conosco"} onClick={handleButtonClick} className='desenvolvimento-de-aplicativos-content-botao-construa-seu-app'>Construa seu aplicativo hoje</Link>
                    </div>
                </div>

            </div>
        </section>);
};

export default ServicesApplicationDevelopment;