import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../utils/GlobalContext';
import './style.css';

const ServicesTechnicalConsultancy = () => {
    const { setGlobalValue } = useContext(GlobalContext);

    const handleButtonClick = () =>  setGlobalValue('Consultoria Técnica (Page: Serviços)');

    return (
        <section className='consultoria-tecnica-content-section'>
            <div className='consultoria-tecnica-content'>

                <div className='consultoria-tecnica-content-img-div'>
                    <div className='consultoria-tecnica-content-img'></div>
                </div>

                <span className='space'></span>
                
                <div className='consultoria-tecnica-content-text'>
                    <h2 className='consultoria-tecnica-content-text-title'>Consultoria Técnica</h2>

                    <div className='consultoria-tecnica-content-text-descricao-div'>
                        <p className='consultoria-tecnica-content-text-descricao'>
                            Conte com nossos consultores técnicos experientes para alcançar seus objetivos de negócios
                            em um ambiente em constante mudança. Oferecemos soluções personalizadas
                            para manter sua vantagem competitiva.
                        </p>

                        <p className='consultoria-tecnica-content-text-descricao'>
                            Na BYG Nexus, nossa equipe de consultores técnicos experientes pode fornecer soluções
                            personalizadas para sua empresa em todas as etapas do processo
                            de desenvolvimento de software.
                        </p>

                        <p className='consultoria-tecnica-content-text-descricao'>
                            Deixe-nos ajudá-lo a encontrar as melhores soluções para sua empresa,
                            para que você possa se concentrar em expandir seus negócios
                        </p>

                        <Link to={"/fale-conosco"} onClick={handleButtonClick} className='consultoria-tecnica-content-botao-encontre-solucoes-para-sua-empresa'> Encontre soluções para sua empresa </Link>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default ServicesTechnicalConsultancy;