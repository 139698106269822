import React from 'react';
import './style.css';

const ContactUsInfo = () => {
    return (
    <section className='faleconosco-info-section'>
        <div className='faleconosco-info-content'>
            <div className='faleconosco-info-content-text'>
                <h2 className='faleconosco-info-text-title'> O que acontece agora? </h2>

                <p className='faleconosco-info-text-descricao'>
                    Assim que recebermos o seu formulário, nossa equipe de suporte começará a
                    trabalhar nele imediatamente. Analisaremos suas perguntas ou comentários
                    com cuidado para entender suas necessidades e requisitos específicos.
                </p>

                <p className='faleconosco-info-text-descricao'>
                    Entraremos em contato em breve para atender suas necessidades.
                    Se precisarmos de mais tempo para investigar, atualizaremos você sobre o progresso.
                    Nosso objetivo é oferecer um excelente atendimento.
                </p>

                <p className='faleconosco-info-text-descricao'>
                    Aguarde nosso contato, estamos trabalhando para atender suas necessidades. <br></br>Agradecemos seu interesse.
                </p>
            </div>

            <span className='space'></span>

            <div className='faleconosco-info-content-img'>
                
            </div>
        </div>
    </section>);
}

export default ContactUsInfo;