import React, { useState, Dispatch, SetStateAction } from 'react';

type GlobalContextType = {
  globalValue: string;
  setGlobalValue: Dispatch<SetStateAction<string>>;
};

const GlobalContext = React.createContext<GlobalContextType>({
  globalValue: '',
  setGlobalValue: () => {}
});

export default GlobalContext;