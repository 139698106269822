import styled from 'styled-components';

interface StyledComponentProps {
    isActive: boolean;
  }

export const LoadingContainer = styled.div<StyledComponentProps>`
display: flex;
justify-content: space-around;

height: ${(props) => (props.isActive ? '100vh' : '0vh')};
width: ${(props) => (props.isActive ? '100vw' : '0vw')};

position: fixed;
z-index: 5;
background-color: var(--bg-black-componentes);
overflow-y: hidden;
animation: hideComponent 3s linear forwards;

@keyframes hideComponent {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const LoadingContentImage = styled.div`
display: flex;
/*flex-direction: column;*/
align-items: center;
justify-content: center;
height: 60vh;
`;