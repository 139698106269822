import React, {useState} from 'react';
import './App.css';
import HomePage from './pages/home';
import ContactUsPage from './pages/contactUs';
import NotFoundPage from './pages/notFound';
import ServicePage from './pages/service';
import GlobalContext from './utils/GlobalContext';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
    const [globalValue, setGlobalValue] = useState('');
  return (
      <Router>
        <GlobalContext.Provider value={{ globalValue, setGlobalValue }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/servicos" element={<ServicePage />} />
            <Route path="/fale-conosco" element={<ContactUsPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </GlobalContext.Provider>
      </Router>
  );
};

export default App;
