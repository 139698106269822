import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const Footer = () => {
    return (
        <footer className="App-footer">
            <div className="footer-bcb-div">

                <div className="footer-content-div">

                    <div className="footer-icon-div">
                        <a><div className="footer-icon-bcb" /></a>

                        <div className='footer-text'>
                            <p className='footer-text-title'>BYG Nexus</p>
                            <p className='footer-text-subtitle'>A lógica a serviço da produtividade</p>

                            <div className='footer-text-endereco'> <div className='icon-localizacao'/> Travessa 14 de Abril, 2132 - Belém/PA</div>

                            <div className='footer-text-infos'> CNPJ: 39.789.155/0001-94 | BYG Nexus Since 2020</div>
                        </div>
                    </div>

                    <div className='footer-nav-links'>
                        <div className='nav'>
                            <Link to={"/"} className='nav-link'>Home</Link>
                            <Link to={"/servicos"} className='nav-link'>Serviços</Link>
                        </div>

                        <div className='icons'>
                            <Link to={"https://www.instagram.com/bygnexus/"}> <div className='icon-instagram' /> </Link>
                            <Link to={"https://www.linkedin.com/company/byg-nexus/"}> <div className='icon-linkedin' /> </Link>
                        </div>
                    </div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;