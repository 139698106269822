import React from 'react';
import './header.css';
import '../../assets/images/logo-byg.png';
import { Link } from 'react-router-dom';
import Menu from './components/Menu';

const Header = () => {
    return (
        <header className="App-header" >
            <div className="header-bcb-div">
                <div className="header-content-div">
                    <Link className="header-icon-div" to={"/"}>
                        <div className="icon-bcb" />
                    </Link>

                    <Menu/>
                </div>
            </div>
        </header>);
};

export default Header;