import React, { useState, useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component';
import './style.css';
const slides = [
    { id: 0, title: 'Extremamente profissionais, atenciosos em todos os detalhes do que pedi. Estou muito satisfeito', testimony: 'Paulo Rivelino, Diretor da AVERTEX' },
    { id: 1, title: 'A equipe da BYG foi muito atenciosa comigo, sanaram todas as minhas duvidas e me deixaram muito confortavel para seguirmos com o projeto. Estou extremamente satisfeita!', testimony: 'Cláudia Valle, CEO do Alquimia do Cheff' },
    { id: 2, title: 'O serviço aplicado atendeu o que estava proposto assim como a equipe se dedicou e buscou ser o mais solicito possivel para resolver eventualidades e também repasse de como manipular  o software, dando total suporte na troca de conhecimento.', testimony: 'Junior Gemaque, Ex-Engenheiro Florestal da Unifloresta' }
];

const HomeTestimony = () => {
    const flickityOptions = {
        imagesLoaded: true,
        pageDots: true,
        prevNextButtons: true,
    };
   
    return (
        <Flickity className={'carousel'} options={flickityOptions}>
            <div className="carousel c1">
                <h3 className='slide-depoimento title0'>{slides[0].title}</h3>
                <div className='slide-testemunha testimony0'>- {slides[0].testimony}</div>
            </div>

            <div className="carousel c2">
                <h3 className='slide-depoimento title1'>{slides[1].title}</h3>
                <div className='slide-testemunha testimony1'>- {slides[1].testimony}</div>
            </div>

            <div className="carousel c3">
                <h3 className='slide-depoimento title2'>{slides[2].title}</h3>
                <div className='slide-testemunha testimony2'>- {slides[2].testimony}</div>
            </div>
        </Flickity>
    );
}

export default HomeTestimony;