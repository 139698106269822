import React from 'react';
import { WhyWeChangeContainer, WhyWeChangeContent, WhyWeChangeText } from './styled';

const WhyWeChange = () => {

    return (
        <WhyWeChangeContainer>
            <WhyWeChangeContent>

                <WhyWeChangeText>
                    <h2 className='whywechange-content-title'> Por que escolhemos Nexus como parte do nosso novo nome? </h2>

                    <p className='whywechange-content-subtitle'>
                        Optamos pelo nome 'Nexus' porque simboliza conexão, algo que buscamos oferecer aos nossos
                        clientes. Essa escolha reflete nossa paixão pela inovação e nossa busca constante por um ponto
                        de convergência, onde tecnologias, habilidades e ideias se unem para criar algo novo e surpreendente.
                    </p>
                </WhyWeChangeText>

                <div className='whywechange-content-image'>
                    <div className='whywechange-image'></div>
                </div>
            </WhyWeChangeContent>
        </WhyWeChangeContainer>
    );
};

export default WhyWeChange;