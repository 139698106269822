import React from 'react';
import './style.css';

const ServicesBanner = () => {
    return (
    <section className='banner-content-section'>
        <div className='banner-section'></div>
        <div className='banner-content-text'>
            <div className="banner-content-title-text">A BCB Digital agora é BYG Nexus</div>
            <div className="banner-content-subtitle-text">Junte-se a nós nesta nova jornada de evolução! <br/><br/>
                Com o nosso novo nome, BYG Nexus, estamos prontos para continuar criando soluções inovadoras e
                entregando resultados excepcionais para nossos clientes.
                <br/><br/>Venha conosco e faça parte dessa história de sucesso!</div>
        </div>
    </section>
    );
}

export default ServicesBanner;