import React from 'react';
import './styles.css'

const HomeBanner  = () => {
    return (
        <section className='home--home-banner-section'>
            <div className='home--home-banner-text'>
                <div className='home--home-banner-text-title'>BYG Nexus: A lógica a serviço da produtividade</div>

                <div className='home--home-banner-text-subtitle'>
                    A BYG Nexus surgiu para transformar a indústria de software. Com uma equipe experiente,
                    usamos tecnologias avançadas para oferecer soluções personalizadas para empresas de todos os
                    tamanhos. Nossos softwares são intuitivos, fáceis de usar e simplificam as operações, otimizando o
                    fluxo de trabalho, aumentando a eficiência e a produtividade.
                </div>
            </div>
        </section>
    );
};

export default HomeBanner;