import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const HomePresentation = () => {
    return (
        <section className='apresentacao-content-container'>
                <div className="apresentacao-content">
                    <div className="apresentacao-content-text-div">
                        <div className='apresentacao-content-text-descricao-div'>
                            <div className="apresentacao-content-text-title">
                                Bem vindos a BYG Nexus
                            </div>
                            <div className="apresentacao-content-text-descricao">
                                A BYG Nexus cria soluções de software inovadoras e de alta qualidade para as
                                necessidades da indústria, com uma equipe experiente que entende a importância de
                                soluções personalizadas.
                            </div>
                            <div className="apresentacao-content-text-descricao">
                                Nós oferecemos uma variedade de serviços, desde software personalizado até design de
                                aplicativos e soluções de automação. Nosso objetivo é fornecer soluções acessíveis
                                que atendam às suas necessidades de negócios.
                            </div>
                            <div className="apresentacao-content-text-descricao">
                                Descubra todas as formas que podemos ajudar.
                                Clique no botão abaixo para conhecer nossos serviços
                            </div>
                            <Link to={"/servicos"} className="apresentacao-content-botao-servicos">
                                Conheça nossos serviços
                            </Link>
                        </div>
                    </div>

                    <div className='apresentacao-content-img-div'>
                        <div className='apresentacao-content-img'>

                        </div>
                    </div>
                </div>
        </section>
    );
};

export default HomePresentation;